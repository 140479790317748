import { baseAxios } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export async function importDIK(data) {
  // console.log(data, "LLLLL");
  try {
    const response = await baseAxios.post("webDik/importDik", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // console.log(response, "LLLLL");
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function importDIKMaster(data) {
  // console.log(data, "LLLLL");
  try {
    const response = await baseAxios.post("webDik/importDikMaster", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // console.log(response, "LLLLL");
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function importSKT(data) {
  // console.log(data, "LLLLL");
  try {
    const response = await baseAxios.post("webDik/importSkt", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // console.log(response, "AAAAA");
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function refreshAll(data) {
  // console.log(data, "LLLLL");
  try {
    const response = await baseAxios.post("webDik/synchronizeBapBpd", data, {
      headers: { token: localStorage.getItem("token") },
    });
    console.log(response, "AAAAA");
    if (response.status === 200) {
      Swal.fire({
        title: "Success",
        text: "Synchronization was successful",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      Swal.fire({
        title: "Error",
        text: "Bad request. Please check your input and try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: "An error occurred during synchronization",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    return error.response;
  }
}

export async function getAllGabungan(dispatch, data, history) {
  try {
    const response = await baseAxios.get("webDik/getGabungan", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_GABUNGAN", data: response.data });
    // console.log(response, "HHHHHHHHHHH");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function importData(formData, tipe) {
  try {
    const response = await baseAxios.post(
      `webImport/importData?tipe=${tipe}`,
      formData,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllImportDataAbsensi(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webImport/getData?tipe=${data.tipe}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_ABSENSI", data: response.data.data });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllImportDataKlien(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webImport/getData?tipe=${data.tipe}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_KLIEN", data: response.data.data });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllImportDataKoperasi(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webImport/getData?tipe=${data.tipe}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_KOPERASI", data: response.data.data });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllImportDataSkt(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webImport/getData?tipe=${data.tipe}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_SKT", data: response.data.data });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllImportDatakaryawan(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webImport/getData?tipe=${data.tipe}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_KARYAWAN", data: response.data.data });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllImportDataInvoice(dispatch, data) {
  try {
    const response = await baseAxios.get(
      `webImport/getData?tipe=${data.tipe}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_INVOICE", data: response.data.data });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function uploadScreenShoot(data) {
  // console.log(data, "LLLLL");
  try {
    const response = await baseAxios.post("webImport/upload", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // console.log(response, "AAAAA");
    return response;
  } catch (error) {
    return error.response;
  }
}
