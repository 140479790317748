const initialState = {
  totalGabungan: [],
  totalAbsensi: [],
  totalKlien: [],
  totalKoperasi: [],
  totalSkt: [],
  totalKaryawan: [],
  totalInvoice: [],
};

const dashboardUtamaReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALL_GABUNGAN":
      return {
        ...state,
        totalGabungan: data,
      };
    case "SET_GET_ABSENSI":
      return {
        ...state,
        totalAbsensi: data,
      };
    case "SET_GET_KLIEN":
      return {
        ...state,
        totalKlien: data,
      };
    case "SET_GET_KOPERASI":
      return {
        ...state,
        totalKoperasi: data,
      };
    case "SET_GET_SKT":
      return {
        ...state,
        totalSkt: data,
      };
    case "SET_GET_KARYAWAN":
      return {
        ...state,
        totalKaryawan: data,
      };
    case "SET_GET_INVOICE":
      return {
        ...state,
        totalInvoice: data,
      };
    default:
      return state;
  }
};
export default dashboardUtamaReducer;
