import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {
  VictoryChart,
  VictoryTheme,
  VictoryStack,
  VictoryArea,
  VictoryLine,
  VictoryTooltip,
} from "victory";
import "assets/img/logo-sulutgo.png";
import { importDIK } from "stores";
import { importDIKMaster } from "stores";
import { importSKT } from "stores";
import { refreshAll } from "stores";
import { getAllGabungan } from "stores";

function DashboardUtama() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const dashboard = useSelector((state) => state.dashboardUtamaReducer);
  // console.log(dashboard, "HHHHHHHHHHH");

  const [excelDIK, setExcelDIK] = React.useState([]);
  const [modalImportDIK, setModalIMportDIK] = React.useState(false);
  const [excelDIKMaster, setExcelDIKMaster] = React.useState([]);
  const [modalImportDIKMaster, setModalIMportDIKMaster] = React.useState(false);
  const [excelSKT, setExcelSKT] = React.useState([]);
  const [modalImportSKT, setModalIMportSKT] = React.useState(false);
  const [listAllGabungan, setListAllGabungan] = React.useState([]);

  const handleResetAll = async () => {
    const response = await refreshAll();
    if (response && response) {
      console.log("Refresh successful", response.data);
      // Lakukan sesuatu setelah refresh berhasil, jika diperlukan
    } else {
      console.error("Refresh failed", response);
    }
  };

  function submitImportDIK(e) {
    e.preventDefault();

    if (excelDIK.length === 0) {
      Swal.fire({
        title: "Pilih file terlebih dahulu !",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    const formData = new FormData();
    excelDIK.forEach((file) => {
      formData.append("importDik", file);
    });

    importDIK(formData)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          setModalIMportDIK(false);
          setExcelDIK([]);
          // getAllSimpanan(dispatch, {
          //   cari: "",
          //   page: page + 1,
          //   limit: totalPerHalaman,
          // });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat mengirim data. Silakan coba lagi.",
        });
      });
  }

  function submitImportDIKMaster(e) {
    e.preventDefault();

    if (excelDIKMaster.length === 0) {
      Swal.fire({
        title: "Pilih file terlebih dahulu !",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    const formData = new FormData();
    excelDIKMaster.forEach((file) => {
      formData.append("importDik", file);
    });

    importDIKMaster(formData)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          setModalIMportDIKMaster(false);
          setExcelDIKMaster([]);
          // getAllSimpanan(dispatch, {
          //   cari: "",
          //   page: page + 1,
          //   limit: totalPerHalaman,
          // });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat mengirim data. Silakan coba lagi.",
        });
      });
  }

  function submitImportSKT(e) {
    e.preventDefault();

    if (excelSKT.length === 0) {
      Swal.fire({
        title: "Pilih file terlebih dahulu !",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    const formData = new FormData();
    excelSKT.forEach((file) => {
      formData.append("importDik", file);
    });

    importSKT(formData)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          setModalIMportSKT(false);
          setExcelSKT([]);
          // getAllSimpanan(dispatch, {
          //   cari: "",
          //   page: page + 1,
          //   limit: totalPerHalaman,
          // });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat mengirim data. Silakan coba lagi.",
        });
      });
  }

  function handleFileChange(e) {
    const excelDik = Array.from(e.target.files);
    setExcelDIK(excelDik);
  }

  function handleFileChangeMaster(e) {
    const excelDikMaster = Array.from(e.target.files);
    setExcelDIKMaster(excelDikMaster);
  }

  function handleFileChangeSKT(e) {
    const excelSkt = Array.from(e.target.files);
    setExcelSKT(excelSkt);
  }

  const removeExcel = (index) => {
    setExcelDIK(excelDIK.filter((_, i) => i !== index));
  };

  const removeExcelDIKMaster = (index) => {
    setExcelDIKMaster(excelDIKMaster.filter((_, i) => i !== index));
  };

  const removeExcelSkt = (index) => {
    setExcelSKT(excelSKT.filter((_, i) => i !== index));
  };

  const handleCloseModal = () => {
    setExcelDIK([]);
    setExcelDIKMaster([]);
    setExcelSKT([]);
  };

  // React.useEffect(() => {
  //   let tmp =
  //     dashboard.totalGabungan.data &&
  //     dashboard.totalGabungan.data.map((val, index) => {
  //       console.log(val, "VALUE");
  //       return {
  //         ...val,
  //         action: <></>,
  //       };
  //     });

  //   setListAllGabungan(tmp);
  // }, [dashboard.totalGabungan]);

  const sampleData = [
    {
      x: "Jan",
      bap: 2444,
      bpd: 541,
      bkk_karanganyar: 1050,
      bkk_blitar: 1627,
      bkk_pare: 1453,
      masuk: 274,
      keluar: 130,
    },
    {
      x: "Feb",
      bap: 2452,
      bpd: 520,
      bkk_karanganyar: 1027,
      bkk_blitar: 1630,
      bkk_pare: 1460,
      masuk: 193,
      keluar: 98,
    },
    {
      x: "Mar",
      bap: 2460,
      bpd: 526,
      bkk_karanganyar: 1017,
      bkk_blitar: 1617,
      bkk_pare: 1612,
      masuk: 112,
      keluar: 93,
    },
    {
      x: "Apr",
      bap: 2434,
      bpd: 543,
      bkk_karanganyar: 984,
      bkk_blitar: 1553,
      bkk_pare: 1586,
      masuk: 113,
      keluar: 89,
    },
    {
      x: "May",
      bap: 2473,
      bpd: 544,
      bkk_karanganyar: 968,
      bkk_blitar: 1548,
      bkk_pare: 1596,
      masuk: 116,
      keluar: 87,
    },
    {
      x: "Jun",
      bap: 2472,
      bpd: 541,
      bkk_karanganyar: 961,
      bkk_blitar: 1541,
      bkk_pare: 1584,
      masuk: 117,
      keluar: 86,
    },
  ];

  const [selectedArea, setSelectedArea] = React.useState(null);

  const handleAreaClick = (area) => {
    setSelectedArea(selectedArea === area ? null : area);
  };

  React.useEffect(() => {
    getAllGabungan(dispatch).then((response) => {
      const gabungan = response.data.data[0];
      console.log(gabungan, "PPPPPP");

      setListAllGabungan(gabungan);
    });
  }, []);
  // React.useEffect(() => {
  //   getAllGabungan(dispatch);
  // }, []);
  return (
    <>
      <Modal
        size="lg"
        show={modalImportDIK}
        onHide={() => {
          handleCloseModal();
          setModalIMportDIK(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Daily Import DIK
                        <hr></hr>
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "grey",
                        textTransform: "uppercase",
                      }}
                    >
                      Import File DIK
                    </label>
                    <Row>
                      <Col sm="12">
                        <label
                          className="label-input-file btn text-white mb-4"
                          htmlFor="import2"
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#12E193",
                            border: 0,
                          }}
                        >
                          <div
                            style={{
                              color: "#02160F",
                            }}
                          >
                            Pilih File
                          </div>

                          <input
                            type="file"
                            id="import2"
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            accept=".xls,.xlsx"
                            multiple
                          />
                        </label>
                        {excelDIK.map((file, index) => (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <p className="mb-1 mr-1">{file.name}</p>
                            <button
                              onClick={() => removeExcel(index)}
                              className="btn btn-link remove text-danger"
                            >
                              <i className="fa fa-times" />
                            </button>
                          </div>
                        ))}
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
                <div style={{ marginTop: 30, textAlign: "end" }}>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    // variant="info"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#A2CFFE",
                      border: 0,
                      color: "black",
                    }}
                    onClick={submitImportDIK}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalImportDIKMaster}
        onHide={() => {
          handleCloseModal();
          setModalIMportDIKMaster(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Import DIK Master
                        <hr></hr>
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "grey",
                        textTransform: "uppercase",
                      }}
                    >
                      Import File DIK Master
                    </label>
                    <Row>
                      <Col sm="12">
                        <label
                          className="label-input-file btn text-white mb-4"
                          htmlFor="import2"
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#12E193",
                            border: 0,
                          }}
                        >
                          <div
                            style={{
                              color: "#02160F",
                            }}
                          >
                            Pilih File
                          </div>

                          <input
                            type="file"
                            id="import2"
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            onChange={handleFileChangeMaster}
                            style={{ display: "none" }}
                            accept=".xls,.xlsx"
                            multiple
                          />
                        </label>
                        {excelDIKMaster.map((file, index) => (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <p className="mb-1 mr-1">{file.name}</p>
                            <button
                              onClick={() => removeExcelDIKMaster(index)}
                              className="btn btn-link remove text-danger"
                            >
                              <i className="fa fa-times" />
                            </button>
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
                <div style={{ marginTop: 30, textAlign: "end" }}>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    // variant="info"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#A2CFFE",
                      border: 0,
                      color: "black",
                    }}
                    onClick={submitImportDIKMaster}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalImportSKT}
        onHide={() => {
          handleCloseModal();
          setModalIMportSKT(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Import SKT
                        <hr></hr>
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "grey",
                        textTransform: "uppercase",
                      }}
                    >
                      Import File SKT
                    </label>
                    <Row>
                      <Col sm="12">
                        <label
                          className="label-input-file btn text-white mb-4"
                          htmlFor="import2"
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#12E193",
                            border: 0,
                          }}
                        >
                          <div
                            style={{
                              color: "#02160F",
                            }}
                          >
                            Pilih File
                          </div>

                          <input
                            type="file"
                            id="import2"
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            onChange={handleFileChangeSKT}
                            style={{ display: "none" }}
                            accept=".xls,.xlsx"
                            multiple
                          />
                        </label>
                        {excelSKT.map((file, index) => (
                          <div
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <p className="mb-1 mr-1">{file.name}</p>
                            <button
                              onClick={() => removeExcelSkt(index)}
                              className="btn btn-link remove text-danger"
                            >
                              <i className="fa fa-times" />
                            </button>
                          </div>
                        ))}
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
                <div style={{ marginTop: 30, textAlign: "end" }}>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    // variant="info"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#A2CFFE",
                      border: 0,
                      color: "black",
                    }}
                    onClick={submitImportSKT}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <div
        className="card"
        style={{ backgroundColor: "#D9F0F0", borderRadius: 10 }}
      >
        <h4
          className="title mt-2"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Import
          <hr></hr>
        </h4>
        <div className="card-body">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            <div className="d-flex flex-column flex-md-row align-items-center">
              <Button
                className="btn-wd mr-0 mb-2 mb-md-0"
                onClick={() => {
                  setModalIMportDIK(true);
                }}
                style={{
                  marginLeft: 20,
                  backgroundColor: "#58A399",
                  borderColor: "#79AC78",
                  fontWeight: "bold",
                  border: 0,
                }}
              >
                <i
                  className="fas fa-file-excel fa-lg"
                  style={{ marginRight: 10, fontWeight: "bold" }}
                ></i>
                Daily Import DIK
              </Button>
              <Button
                className="btn-wd mr-0 mb-2 mb-md-0"
                onClick={() => {
                  setModalIMportDIKMaster(true);
                }}
                style={{
                  marginLeft: 20,
                  backgroundColor: "#58A399",
                  borderColor: "#79AC78",
                  fontWeight: "bold",
                  border: 0,
                }}
              >
                <i
                  className="fas fa-file-excel fa-lg"
                  style={{ marginRight: 10, fontWeight: "bold" }}
                ></i>
                Import DIK Master
              </Button>
              <Button
                className="btn-wd mr-0 mb-2 mb-md-0"
                onClick={() => {
                  setModalIMportSKT(true);
                }}
                style={{
                  marginLeft: 20,
                  backgroundColor: "#58A399",
                  borderColor: "#79AC78",
                  fontWeight: "bold",
                  border: 0,
                }}
              >
                <i
                  className="fas fa-file-excel fa-lg"
                  style={{ marginRight: 10, fontWeight: "bold" }}
                ></i>
                Import SKT
              </Button>
            </div>
            <div>
              <Button
                className="btn-wd mr-0"
                onClick={() => refreshAll({})}
                style={{
                  marginLeft: 20,
                  backgroundColor: "#58A399",
                  borderColor: "#79AC78",
                  fontWeight: "bold",
                  border: 0,
                }}
              >
                <i
                  className="fas fa-redo"
                  style={{ marginRight: 10, fontWeight: "bold" }}
                ></i>
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Card style={{ backgroundColor: "#D9F0F0", borderRadius: 10 }}>
        <Container fluid>
          <Row style={{ justifyContent: "start" }}>
            <Col
              lg="3"
              className="d-flex justify-content-center align-items-center"
            >
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: 10,
                  marginTop: 15,
                  backgroundColor: "#017371",
                }}
              >
                <Card.Body className="d-flex align-items-center p-3">
                  <Col
                    md="4"
                    className="d-flex justify-content-start align-items-center"
                  >
                    <Image
                      src={
                        "https://ptbap.net/assets/images/logo/invert-bap.png"
                      }
                      width="auto"
                      height="50px"
                    />
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col
              lg="7"
              className="d-flex justify-content-center align-items-center"
            >
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: 10,
                  marginTop: 15,
                  backgroundColor: "#017371",
                }}
              >
                <Card.Body className="d-flex justify-content-center align-items-center p-3">
                  <b
                    style={{
                      color: "white",
                      fontSize: 28,
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    dashboard data induk karyawan (dik)
                  </b>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
          {/* <h4
            className="title mt-2"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Informasi
            <hr></hr>
          </h4>
          <Row>
            <Col lg="12" style={{ marginTop: 12 }}>
              <Row>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Total BAP</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalBap ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Total BPD</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalBpd ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>
                          Total Office & School
                        </b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalOficeSchool ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Total Industri</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalIndustry ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Total Lainnya</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalOther ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Total Bank Finance</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalBankFinance ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Total BIA pare</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalBiaPare ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Total BIK Blitar</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalBikBlitar ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>
                          Total BIK Karanganyar
                        </b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.totalBikKaranganyar ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Keluar BAP</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.keluarBap ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Keluar BPD</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.keluarBpd ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Masuk BAP</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.masukBap ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      marginTop: 8,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#05476B",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "#D9E8F0" }}>Masuk BPD</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {listAllGabungan?.masukBpd ?? 0} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row> */}

          <Card.Body>
            <h4
              className="title mt-2"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Grafik
              <hr />
            </h4>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "#2E8B57",
                  width: 15,
                  height: 15,
                  marginRight: 5,
                }}
              ></div>
              <label style={{ fontSize: 14 }}>BIA Pare</label>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "#00FF7F",
                  width: 15,
                  height: 15,
                  marginLeft: 20,
                  marginRight: 5,
                }}
              ></div>
              <label style={{ fontSize: 14 }}>BIK Karanganyar</label>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "#32CD32",
                  width: 15,
                  height: 15,
                  marginLeft: 20,
                  marginRight: 5,
                }}
              ></div>
              <label style={{ fontSize: 14 }}>BIK Blitar</label>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "#98FB98",
                  width: 15,
                  height: 15,
                  marginLeft: 20,
                  marginRight: 5,
                }}
              ></div>
              <label style={{ fontSize: 14 }}>BPD</label>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "#8FBC8F",
                  width: 15,
                  height: 15,
                  marginLeft: 20,
                  marginRight: 5,
                }}
              ></div>
              <label style={{ fontSize: 14 }}>BAP</label>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "#FFA500",
                  width: 15,
                  height: 15,
                  marginLeft: 20,
                  marginRight: 5,
                }}
              ></div>
              <label style={{ fontSize: 14 }}>Keluar</label>
              <div
                style={{
                  display: "inline-block",
                  backgroundColor: "#FF0000",
                  width: 15,
                  marginLeft: 20,
                  height: 15,
                  marginRight: 5,
                }}
              ></div>
              <label style={{ fontSize: 14 }}>Masuk</label>
            </div>
            <Row>
              <Col md="12">
                <VictoryChart
                  theme={VictoryTheme.material}
                  height={300}
                  width={900}
                >
                  <VictoryStack>
                    <VictoryArea
                      data={sampleData}
                      x="x"
                      y="bap"
                      style={{
                        data: {
                          fill: selectedArea === "bap" ? "#4682B4" : "#8FBC8F",
                          cursor: "pointer",
                        },
                      }}
                      events={[
                        {
                          target: "data",
                          eventHandlers: {
                            onClick: () => handleAreaClick("bap"),
                          },
                        },
                      ]}
                      labels={({ datum }) => `${datum.x}: ${datum.bap}`}
                      labelComponent={<VictoryTooltip />}
                    />
                    <VictoryArea
                      data={sampleData}
                      x="x"
                      y="bpd"
                      style={{
                        data: {
                          fill: selectedArea === "bpd" ? "#4682B4" : "#98FB98",
                          cursor: "pointer",
                        },
                      }}
                      events={[
                        {
                          target: "data",
                          eventHandlers: {
                            onClick: () => handleAreaClick("bpd"),
                          },
                        },
                      ]}
                      labels={({ datum }) => `${datum.x}: ${datum.bpd}`}
                      labelComponent={<VictoryTooltip />}
                    />
                    <VictoryArea
                      data={sampleData}
                      x="x"
                      y="bkk_karanganyar"
                      style={{
                        data: {
                          fill:
                            selectedArea === "bkk_karanganyar"
                              ? "#4682B4"
                              : "#00FF7F",
                          cursor: "pointer",
                        },
                      }}
                      events={[
                        {
                          target: "data",
                          eventHandlers: {
                            onClick: () => handleAreaClick("bkk_karanganyar"),
                          },
                        },
                      ]}
                      labels={({ datum }) =>
                        `${datum.x}: ${datum.bkk_karanganyar}`
                      }
                      labelComponent={<VictoryTooltip />}
                    />
                    <VictoryArea
                      data={sampleData}
                      x="x"
                      y="bkk_blitar"
                      style={{
                        data: {
                          fill:
                            selectedArea === "bkk_blitar"
                              ? "#4682B4"
                              : "#32CD32",
                          cursor: "pointer",
                        },
                      }}
                      events={[
                        {
                          target: "data",
                          eventHandlers: {
                            onClick: () => handleAreaClick("bkk_blitar"),
                          },
                        },
                      ]}
                      labels={({ datum }) => `${datum.x}: ${datum.bkk_blitar}`}
                      labelComponent={<VictoryTooltip />}
                    />
                    <VictoryArea
                      data={sampleData}
                      x="x"
                      y="bkk_pare"
                      style={{
                        data: {
                          fill:
                            selectedArea === "bkk_pare" ? "#4682B4" : "#2E8B57",
                          cursor: "pointer",
                        },
                      }}
                      events={[
                        {
                          target: "data",
                          eventHandlers: {
                            onClick: () => handleAreaClick("bkk_pare"),
                          },
                        },
                      ]}
                      labels={({ datum }) => `${datum.x}: ${datum.bkk_pare}`}
                      labelComponent={<VictoryTooltip />}
                    />
                  </VictoryStack>
                  <VictoryLine
                    data={sampleData}
                    x="x"
                    y="masuk"
                    style={{ data: { stroke: "#FF0000", strokeWidth: 2 } }}
                    labels={({ datum }) => `${datum.x}: ${datum.masuk}`}
                    labelComponent={<VictoryTooltip />}
                  />
                  <VictoryLine
                    data={sampleData}
                    x="x"
                    y="keluar"
                    style={{ data: { stroke: "#FFA500", strokeWidth: 2 } }}
                    labels={({ datum }) => `${datum.x}: ${datum.keluar}`}
                    labelComponent={<VictoryTooltip />}
                  />
                </VictoryChart>
              </Col>
            </Row>
          </Card.Body>
        </Container>
      </Card>
    </>
  );
}

export default DashboardUtama;
