import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxSoldierMiddleware } from "redux-soldier";
//import axios from "axios";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import userReducer from "./user";
import dashboardUtamaReducer from "./dashboardUtama";

// function
export * from "./auth/function";
export * from "./errorHandler";
export * from "./user/function";
export * from "./dashboardUtama/function";

// server api
// export const baseUrl = process.env.REACT_APP_URL
export const baseUrl = "https://apibapreport.ptbap.net";
// export const baseUrl = "https://1gbx4t4f-9050.asse.devtunnels.ms/";
// export const baseUrl = "http://192.168.110.49:9050/";
// export const baseUrl = "https://d5j8gps7-9051.asse.devtunnels.ms/";
// export const baseUrl = "http://localhost:9050/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  //timeout: 10000,
});

const rootReducer = combineReducers({
  userReducer,
  authReducer,
  dashboardReducer,
  dashboardUtamaReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
