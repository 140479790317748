import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Card,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import { uploadScreenShoot } from "stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const ResumeNew = () => {
  const auth = useSelector((state) => state.authReducer);
  // console.log(auth, "authhh");
  const history = useHistory();
  const role = auth.role;
  const [modalUploadSS, setModalUploadSS] = useState(false);
  const [gambarSS, setGambarSS] = useState([]);
  const [imageUrl, setImageUrl] = useState(""); // State untuk menyimpan URL gambar
  const token = localStorage.getItem("token");

  function handleFileChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setGambarSS(selectedFiles);
  }

  const removeImage = (index) => {
    setGambarSS(gambarSS.filter((_, i) => i !== index));
  };

  const handleCloseModal = () => {
    setModalUploadSS(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    gambarSS.forEach((file) => {
      formData.append("screeenshotDashboard", file);
    });

    Swal.fire({
      title: "Uploading...",
      text: "Please wait while your screenshot is being uploaded.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await uploadScreenShoot(formData);
      Swal.close();

      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "There was an error uploading the screenshot.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.close();
      Swal.fire({
        title: "Error!",
        text: "There was an error uploading the screenshot.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    const fetchImageUrl = async () => {
      const response = await fetch(
        "https://apibapreport.ptbap.net/screenshotDashboard/ss.png"
      );
      if (response.ok) {
        setImageUrl(
          "https://apibapreport.ptbap.net/screenshotDashboard/ss.png"
        );
      } else {
        setImageUrl("assets/img/sorry.jpg");
      }
    };

    fetchImageUrl();
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalUploadSS}
        onHide={handleCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <Form.Group>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Pilih Gambar
                  </Form.Label>
                  <Row>
                    <Col sm="12">
                      <label
                        className="label-input-file btn text-white mb-4"
                        htmlFor="import2"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#12E193",
                          border: 0,
                        }}
                      >
                        <div
                          style={{
                            color: "#02160F",
                          }}
                        >
                          Pilih File
                        </div>

                        <input
                          type="file"
                          id="import2"
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          accept=".png, .jpeg, .jpg"
                          multiple
                        />
                      </label>
                      {gambarSS.map((file, index) => (
                        <div className="d-flex align-items-center" key={index}>
                          <p className="mb-1 mr-1">{file.name}</p>
                          <button
                            onClick={() => removeImage(index)}
                            className="btn btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </button>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </Form.Group>

                <Button
                  type="submit"
                  className="btn-fill pull-right"
                  style={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    backgroundColor: "#A2CFFE",
                    border: 0,
                    color: "black",
                  }}
                >
                  Submit
                </Button>
              </Card.Body>
            </Card>
          </Form>
        </Modal.Body>
      </Modal>

      <Container fluid className="p-3">
        {/* <Row className="justify-content-center">
          <Col lg={12}>
            <Card className="shadow rounded mb-3 header-card">
              <Card.Body className="d-flex align-items-center p-3">
                <Col className="d-flex justify-content-start align-items-center">
                  <img
                    src="https://ptbap.net/assets/images/logo/invert-bap.png"
                    alt="Logo"
                    width="auto"
                    height="50px"
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}

        <Row>
          <Col md="12" style={{ margin: 0 }}>
            {/* <Card style={{ height: "100%" }}> */}
            {/* <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                Image Update Report
                <hr />
              </Card.Title>
            </Card.Header> */}

            {/* <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            > */}
            <img
              src={imageUrl}
              alt="Image"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
            {!token && (
              <div
                style={{
                  margin: 20,
                  fontSize: 18,
                  color: "#007bff",
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                }}
              >
                <Link to="/auth/login-page">
                  <b>Update Report</b>
                </Link>
              </div>
            )}
            {/* </Card.Body> */}
            {/* </Card> */}
          </Col>
        </Row>
      </Container>
      {role === "admin" ? (
        <Button
          className="btn-wd mr-0 mb-2 mb-md-0"
          onClick={() => {
            setModalUploadSS(true);
          }}
          style={{
            marginLeft: 20,
            backgroundColor: "#58A399",
            borderColor: "#79AC78",
            fontWeight: "bold",
            border: 0,
          }}
        >
          <i
            className="fas fa-images"
            style={{ marginRight: 10, fontWeight: "bold" }}
          ></i>
          Upload Gambar
        </Button>
      ) : null}

      {/* <div className="card-body">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
          <div className="d-flex flex-column flex-md-row align-items-center">
            
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ResumeNew;
