import React from "react";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
  Collapse,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { gantiPassword, logout } from "../../stores";
import { useDispatch, useSelector } from "react-redux";

function AdminNavbar() {
  const auth = useSelector((state) => state.authReducer);
  // console.log(auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalUbahPassword, setModalUbahPassword] = React.useState(false);
  const [modalProfile, setModalProfile] = React.useState(false);
  const [oldPass, setOldPass] = React.useState("");
  const [newPass, setNewPass] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");

  const [passwordMatch, setPasswordMatch] = React.useState(true);
  const [showNewPass, setShowNewPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);

  const handleNewPassChange = (e) => {
    setNewPass(e.target.value);
    setPasswordMatch(e.target.value === confirmPass);
  };

  const handleConfirmPassChange = (e) => {
    setConfirmPass(e.target.value);
    setPasswordMatch(e.target.value === newPass);
  };

  const toggleShowNewPass = () => {
    setShowNewPass(!showNewPass);
  };

  const toggleShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const submitUbahPassword = () => {
    gantiPassword(dispatch, {
      username: auth.nopeg,
      passwordLama: oldPass,
      passwordBaru: newPass,
      role: auth.role,
    });
  };

  React.useEffect(() => {
    document.body.classList.add("sidebar-mini");
    return () => {
      document.body.classList.remove("sidebar-mini");
    };
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalUbahPassword}
        onHide={() => {
          setModalUbahPassword(false);
          setOldPass("");
          setNewPass("");
          setConfirmPass("");
          setPasswordMatch(true);
          setShowNewPass(false);
          setShowConfirmPass(false);
        }}
        // onHide={() => setModalUbahPassword(!modalUbahPassword)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                Ubah Password
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Password Lama
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setOldPass(e.target.value);
                    }}
                    placeholder="Masukkan Password Lama"
                    type="text"
                  ></Form.Control>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginTop: 15,
                    }}
                  >
                    Password Baru
                  </label>
                  <div
                    className="password-input"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Control
                      onChange={handleNewPassChange}
                      style={{ flex: 2 }}
                      placeholder="Masukkan Password Baru"
                      type={showNewPass ? "text" : "password"}
                    />
                    <div
                      className="password-icon"
                      onClick={toggleShowNewPass}
                      style={{ fontSize: 11 }}
                    >
                      {showNewPass ? (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye"></i>
                        </Button>
                      ) : (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye-slash"></i>
                        </Button>
                      )}
                    </div>
                  </div>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginTop: 15,
                    }}
                  >
                    Konfirmasi Password Baru
                  </label>
                  <div
                    className="password-input"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Control
                      onChange={handleConfirmPassChange}
                      placeholder="Konfirmasi Password Baru"
                      type={showConfirmPass ? "text" : "password"}
                      style={{
                        borderColor: passwordMatch ? "" : "red",
                      }}
                    />
                    <span
                      className="password-icon"
                      onClick={toggleShowConfirmPass}
                      style={{ fontSize: 11 }}
                    >
                      {showConfirmPass ? (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye"></i>
                        </Button>
                      ) : (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye-slash"></i>
                        </Button>
                      )}
                    </span>
                  </div>
                  {!passwordMatch && (
                    <p style={{ color: "red" }}>
                      Password harus sama seperti password baru.
                    </p>
                  )}
                </Form.Group>
              </Form>
            </Card.Body>

            <Card.Footer>
              <Button
                className="btn-fill"
                type="submit"
                variant="primary"
                onClick={submitUbahPassword}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* <Modal
        size="lg"
        show={modalProfile}
        onHide={() => {
          setModalProfile(false);
          // setOldPass("");
          // setNewPass("");
          // setConfirmPass("");
          // setPasswordMatch(true);
          // setShowNewPass(false);
          // setShowConfirmPass(false);
        }}
        // onHide={() => setModalUbahPassword(!modalUbahPassword)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Container fluid>
            <div
              className="section-image"
              data-image="../../assets/img/bg5.jpg"
            >
              <Row>
                <Col md="8">
                  <Form action="" className="form" method="">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                          Edit Profile
                          <hr></hr>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col className="pr-1" md="5">
                            <Form.Group>
                              <label>Company (disabled)</label>
                              <Form.Control
                                defaultValue="Creative Code Inc."
                                disabled
                                placeholder="Company"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" md="3">
                            <Form.Group>
                              <label>Username</label>
                              <Form.Control
                                defaultValue="michael23"
                                placeholder="Username"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <Form.Control
                                placeholder="Email"
                                type="email"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>First Name</label>
                              <Form.Control
                                defaultValue="Mike"
                                placeholder="Company"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="6">
                            <Form.Group>
                              <label>Last Name</label>
                              <Form.Control
                                defaultValue="Andrew"
                                placeholder="Last Name"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Address</label>
                              <Form.Control
                                defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                                placeholder="Home Address"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>City</label>
                              <Form.Control
                                defaultValue="Mike"
                                placeholder="City"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" md="4">
                            <Form.Group>
                              <label>Country</label>
                              <Form.Control
                                defaultValue="Andrew"
                                placeholder="Country"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label>Postal Code</label>
                              <Form.Control
                                placeholder="ZIP Code"
                                type="number"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>About Me</label>
                              <Form.Control
                                cols="80"
                                defaultValue="Mike"
                                placeholder="Here can be your description"
                                rows="4"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Update Profile
                        </Button>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
                <Col md="4">
                  <Card className="card-user">
                    <Card.Header className="no-padding">
                      <div className="card-image">
                        <img
                          alt="..."
                          src={
                            require("assets/img/full-screen-image-3.jpg")
                              .default
                          }
                        ></img>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="author">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar border-gray"
                            src={
                              require("assets/img/default-avatar.png").default
                            }
                          ></img>
                          <Card.Title as="h5">Tania Keatley</Card.Title>
                        </a>
                        <p className="card-description">michael24</p>
                      </div>
                      <p className="card-description text-center">
                        Hey there! As you can see, <br></br>
                        it is already looking great.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
      </Modal> */}

      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              {/* <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button> */}
            </div>
            {/* <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              Buttons
            </Navbar.Brand> */}
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            {/* <Nav className="nav mr-auto" navbar>
              <Form
                className="navbar-form navbar-left navbar-search-form ml-3 ml-lg-0"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
            </Nav> */}
            <Nav navbar>
              {/* <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-414718872"
                  variant="default"
                >
                  <i className="nc-icon nc-bell-55 mr-1"></i>
                  <span className="notification">5</span>
                  <span className="d-lg-none">Notification</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 1
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 2
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 3
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 4
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Notification 5
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </Nav>
            {/* <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-165516306"
                variant="default"
                style={{ color: "black" }}
              >
                <img
                  alt="..."
                  src={require("assets/img/userNav.png").default}
                  height="20PX"
                  style={{ marginBottom: 5 }}
                ></img>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#pablo"
                  onClick={() => setModalProfile(!modalProfile)}
                >
                  <i class="fas fa-user-edit" style={{ marginRight: 10 }}></i>
                  My Profile
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-41471887333"
                variant="default"
                style={{ color: "black" }}
              >
                <img
                  alt="..."
                  src={require("assets/img/menu.png").default}
                  height="25PX"
                  style={{ marginBottom: 5 }}
                ></img>
              </Dropdown.Toggle>
              {/* <Dropdown.Menu
                alignRight
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-email-85"></i>
                    Messages
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-umbrella-13"></i>
                    Help Center
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-settings-90"></i>
                    Settings
                  </Dropdown.Item>
                  <div className="divider"></div>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-lock-circle-open"></i>
                    Lock Screen
                  </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  <i className="nc-icon nc-button-power"></i>
                  Log Out
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-primary"
                  href="#pablo"
                  onClick={() => setModalUbahPassword(!modalUbahPassword)}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   logout();
                  // }}
                >
                  <i class="fa fa-key" style={{ marginRight: 10 }}></i>
                  Ubah Password
                </Dropdown.Item>
              </Dropdown.Menu> */}

              <Dropdown.Menu
                alignRight
                aria-labelledby="navbarDropdownMenuLink"
              >
                {/* <Dropdown.Item
                  className="text-primary"
                  href="#pablo"
                  // onClick={() => setModalUbahPassword(!modalUbahPassword)}
                >
                  <i class="fas fa-user" style={{ marginRight: 10 }}></i>
                  My Profile
                </Dropdown.Item> */}
                {/* <Dropdown.Item
                  className="text-primary"
                  href="#pablo"
                  onClick={() => setModalUbahPassword(!modalUbahPassword)}
                >
                  <i class="fa fa-key" style={{ marginRight: 10 }}></i>
                  Ubah Password
                </Dropdown.Item> */}
                <Dropdown.Item
                  className="text-danger"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  <i className="nc-icon nc-button-power icon-bold"></i>
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
