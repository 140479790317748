import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../stores";
import { Button, Card, Form, Container, Col } from "react-bootstrap";
import "./LoginPage.css";

function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    login(dispatch, { username, password }, history);
  };

  return (
    <div className="login-page">
      <div className="login-page-background"></div>
      <Container className="d-flex align-items-center justify-content-center min-vh-100">
        <Col lg="5" md="8">
          <Form className="form" onSubmit={handleSubmit}>
            <Card className={"card-login " + cardClasses}>
              <Card.Header className="text-center">
                <h3 className="header">
                  Login <hr></hr>
                </h3>
              </Card.Header>
              <Card.Body>
                <Form.Group>
                  <label
                    style={{ fontSize: 14, color: "black", fontWeight: "bold" }}
                  >
                    Username
                  </label>
                  <Form.Control
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter username"
                    type="text"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    style={{ fontSize: 14, color: "black", fontWeight: "bold" }}
                  >
                    Password
                  </label>
                  <Form.Control
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                  />
                </Form.Group>
              </Card.Body>
              <Card.Footer className="text-center">
                <Button
                  className="btn-login"
                  type="submit"
                  style={{ fontWeight: "bold" }}
                >
                  Login
                </Button>
              </Card.Footer>
            </Card>
            <div className="links">
              <a href="/auth/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              <span>|</span>
              <a
                href="/auth/termsandconditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </div>
          </Form>
        </Col>
      </Container>
    </div>
  );
}

export default LoginPage;
