/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";

import LoginPage from "views/Pages/LoginPage.js";
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsConditions from "views/TermsConditions";
import DashboardUtama from "views/DashboardUtama";
import Resume from "views/Resume";
import ResumeNew from "views/ResumeNew";
import UserProfile from "views/Pages/User/UserProfile";

var routes = [
  // {
  //   path: '/dashboard',
  //   layout: '/admin',
  //   name: 'Dashboard',
  //   icon: 'nc-icon nc-chart-pie-35',
  //   component: Dashboard,
  // },
  // {
  //   show: true,
  //   path: "/dashboard",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35 icon-bold",
  //   component: DashboardUtama,
  // },
  // {
  //   show: true,
  //   path: "/resume",
  //   layout: "/admin",
  //   name: "Resume",
  //   icon: "nc-icon nc-chart-pie-35 icon-bold",
  //   component: Resume,
  // },
  {
    show: true,
    path: "/resume",
    layout: "/newResume",
    name: "Resume",
    icon: "nc-icon nc-chart-pie-35 icon-bold",
    component: ResumeNew,
  },
  {
    show: true,
    path: "/resume",
    layout: "/admin",
    name: "Resume",
    icon: "nc-icon nc-chart-pie-35 icon-bold",
    component: ResumeNew,
  },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/client",
  //   name: "Client",
  //   state: "openClient",
  //   icon: "nc-icon nc-bank icon-bold",
  //   views: [
  //     {
  //       show: true,
  //       path: "/listClient",
  //       layout: "/admin",
  //       name: "List Client",
  //       component: ListClient,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/user",
  //   name: "User",
  //   state: "openUser",
  //   icon: "nc-icon nc-circle-09 icon-bold",
  //   views: [
  //     {
  //       show: true,
  //       path: "/listUser",
  //       layout: "/admin",
  //       name: "List User",
  //       component: ListUser,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/cabang",
  //   name: "Cabang",
  //   state: "openCabang",
  //   icon: "nc-icon nc-pin-3 icon-bold",
  //   views: [
  //     {
  //       show: true,
  //       path: "/ListCabang",
  //       layout: "/admin",
  //       name: "List Cabang",
  //       component: ListCabang,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/target",
  //   name: "Target",
  //   state: "openTarget",
  //   icon: "nc-icon nc-chart-bar-32 icon-bold",
  //   views: [
  //     {
  //       show: true,
  //       path: "/TargetKorwil",
  //       layout: "/admin",
  //       name: "Target Korwil",
  //       component: TargetKorwil,
  //     },
  //     {
  //       show: true,
  //       path: "/TargetAreaManager",
  //       layout: "/admin",
  //       name: "Target Area Manager",
  //       component: TargetAreaManager,
  //     },
  //     {
  //       show: true,
  //       path: "/TargetSupervisor",
  //       layout: "/admin",
  //       name: "Target Supervisor",
  //       component: TargetSupervisor,
  //     },
  //     {
  //       show: true,
  //       path: "/TargetMarketing",
  //       layout: "/admin",
  //       name: "Target Marketing",
  //       component: TargetMarketing,
  //     },
  //     {
  //       show: true,
  //       path: "/TargetCabang",
  //       layout: "/admin",
  //       name: "Target Cabang",
  //       component: TargetCabang,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   path: "/pengajuan",
  //   layout: "/admin",
  //   name: "Pengajuan",
  //   icon: "nc-icon nc-single-copy-04 icon-bold",
  //   component: Pengajuan,
  // },
  // {
  //   show: true,
  //   path: "/pencairan",
  //   layout: "/admin",
  //   name: "Pencairan",
  //   icon: "nc-icon nc-money-coins icon-bold",
  //   component: Pencairan,
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/kunjungan",
  //   name: "Kunjungan",
  //   state: "openKunjungan",
  //   icon: "nc-icon nc-bank icon-bold",
  //   views: [
  //     {
  //       show: true,
  //       path: "/calonNasabah",
  //       layout: "/admin",
  //       name: "Calon Nasabah",
  //       component: CalonNasabah,
  //     },
  //     {
  //       show: true,
  //       path: "/listKunjungan",
  //       layout: "/admin",
  //       name: "List Kunjungan",
  //       component: ListKunjungan,
  //     },
  //     {
  //       show: true,
  //       path: "/kunjunganMarketing",
  //       layout: "/admin",
  //       name: "List Kunjungan Marketing",
  //       component: KunjunganMarketing,
  //     },
  //   ],
  // },
  {
    show: false,
    path: "/userprofile",
    layout: "/admin",
    name: "My Profile",
    icon: "far fa-user",
    component: UserProfile,
  },

  //Template Awal
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       show: true,
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       show: true,
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       show: true,
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       show: true,
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       show: true,
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       show: true,
  //       path: "/icons",
  //       layout: "/admin",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       show: true,
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //     },
  //     {
  //       show: true,
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //     },
  //     {
  //       show: true,
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       show: true,
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   show: true,
  //   path: "/calendar",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  // },
  //Template Akhir
  {
    show: false,
    path: "/login-page",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage,
  },
  {
    show: false,
    path: "/privacy",
    layout: "/auth",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    show: false,
    path: "/termsandconditions",
    layout: "/auth",
    name: "Terms & Conditions",
    component: TermsConditions,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  // {
  //   show: false,
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [
  //     // {
  //     //   path: '/user-page',
  //     //   layout: '/admin',
  //     //   name: 'User Page',
  //     //   mini: 'UP',
  //     //   component: UserPage,
  //     // },
  //     {
  //       show: false,
  //       path: "/login-page",
  //       layout: "/auth",
  //       name: "Login Page",
  //       mini: "LP",
  //       component: LoginPage,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: '/monitoringkebersihan',
  //   name: 'Monitoring',
  //   state: 'openKebersihan',
  //   icon: 'nc-icon nc-puzzle-10',
  //   views: [
  //     {
  //       path: '/dashboardmonitoring',
  //       layout: '/admin',
  //       name: 'Dashboard',
  //       mini: 'D',
  //       component: DashboardMonitoringPage,
  //     },
  //     {
  //       path: '/rekapmonitoring',
  //       layout: '/admin',
  //       name: 'Rekap Monitoring',
  //       mini: 'RM',
  //       component: RekapMonitoringPage,
  //     },
  //     {
  //       path: '/settingarea',
  //       layout: '/admin',
  //       name: 'Setting Area',
  //       mini: 'SA',
  //       component: SettingGedungPage,
  //     },
  //     {
  //       path: '/settingshift',
  //       layout: '/admin',
  //       name: 'Setting Shift',
  //       mini: 'SS',
  //       component: SettingShiftPage,
  //     },
  //   ],
  // },
];
export default routes;
